<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />

    <div class="top-container-box">
      <h1>Transaction Log</h1>
    </div>

    <!-- Filter -->
    <div class="container-box">
      <div class="filter-ctn">
        <div class="filter-input">
          <span class="filter-label">Invoice Number</span>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            outlined
            v-model="filter.invoice_number"
            class="form-input"
            placeholder="Invoice Number"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="filter-input">
          <span class="filter-label">Start Date</span>
          <v-text-field
            outlined
            v-model="filter.start_date"
            class="form-input"
            type="date"
            clearable
            dense
          ></v-text-field>
        </div>
        <div class="filter-input">
          <span class="filter-label">End Date</span>
          <v-text-field
            outlined
            type="date"
            v-model="filter.end_date"
            class="form-input"
            clearable
            dense
          ></v-text-field>
        </div>
        <div class="btn-filter-horizontal">
          <Button name="TERAPKAN FILTER" @click="applyFilters" width="200px" />
        </div>
        <div class="btn-filter-horizontal">
          <div class="reset-filter" @click="clearFilter" v-if="isFilterActive">
            RESET FILTER
          </div>
        </div>
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="transactionLogs"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert v-if="transactionLogs.length === 0" :totalData="totalData" />

    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getTransactionLog"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>

    <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
      <b
        >{{ Math.floor((currentPage - 1) * 10 + transactionLogs.length) }} dari
        {{ totalData }}</b
      >
      transaction log yang tertampil
    </p>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
// import NewModal from "@/components/NewModal.vue";
import Loading from "@/components/ilustration/Loading.vue";
import moment from "moment";
import WatchTable from "@/components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";

export default {
  data() {
    return {
      isModal: false,
      isFilterActive: false,
      currentPage: 1,
      total: 0,
      search: "",
      isLoading: false,
      id: 0,
      totalData: 0,
      filter: {
        invoice_number: "",
        start_date: "",
        end_date: "",
      },
      headers: [
        {
          text: "NO",
          value: "no",
          width: "1",
        },
        { text: "FULL NAME", value: "full_name", width: "150" },
        { text: "PRICE", value: "price", width: "100" },
        { text: "TOTAL PRICE", value: "total_price", width: "120" },
        { text: "STATUS", value: "status", width: "100" },
        { text: "CREATED AT", value: "created_at", width: "130" },
        { text: "ACTION", value: "actions", sortable: false, width: "150" },
      ],
      transactionLogs: [],
      transactionLogDetail: {},
      showFilterModal: false,
    };
  },
  components: {
    Button,
    // NewModal,
    Loading,
    WatchTable,
    NoDataAlert,
  },
  methods: {
    async getTransactionLog() {
      await this.$crm_http
        .get(
          `v1/transaction-log/report?invoice_number=${this.filter.invoice_number}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&page=${this.currentPage}&per_page=10`
        )
        .then((response) => {
          this.transactionLogs = response.data.data.transaction_log.map(
            (element, index) => {
              return {
                id: element.id,
                no: (this.currentPage - 1) * 10 + index + 1,
                full_name: element.full_name,
                price: this.$helpers.formatCurrency(element.price),
                total_price: this.$helpers.formatCurrency(element.total_price),
                status: element.status,
                created_at: moment(element.created_at).format(
                  "DD MMM YYYY HH:mm"
                ),
              };
            }
          );
          const t = parseInt(response.data.data.total);
          this.total = Math.ceil(t / 10);
          this.totalData = response.data.data.total;
        })
        .catch(() => (this.transactionLogs = []));
    },

  
    watchItem(item) {
      this.$router.push(`/transaction-log/form/${item.id}`);
      // await this.getTransactionLogDetail(id);
    },

    applyFilters() {
      this.currentPage = 1;
      this.checkFilter();
      this.getTransactionLog();
    },
    checkFilter() {
      if (
        this.filter.start_date ||
        this.filter.end_date || this.filter.invoice_number
      ) {
        this.isFilterActive = true;
      }
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        invoice_number: "",
        start_date: "",
        end_date: "",
      };
      this.getTransactionLog();
    },



    // editItem(item) {
    //   this.$router.push('/transaction-log/edit/' + item.id);
    // },

    // deleteItem(item) {
    //   this.isModal = true;
    //   this.id = item.id;
    // },

    // async onDelete() {
    //   this.isLoading = true;
    //   try {
    //     await this.$web_http.delete('/v1/transaction-log/' + this.id);
    //     this.getTransactionLogs();
    //     this.isModal = false;
    //   } catch (err) {
    //     console.log(err);
    //   } finally {
    //     this.isLoading = false;
    //   }
    // },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
  },
  mounted() {
    this.getTransactionLog();
  },
};
</script>

<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 30px;
  margin-left: 5px;
}
.filter-ctn {
  display: flex;
  flex-wrap: wrap;
}

.filter-input {
  margin-right: 20px; /* Menambahkan jarak antara elemen filter */
}

.filter-btn {
  margin-top: 10px; /* Menambahkan jarak antara baris filter dan tombol */
  margin-right: 20px; /* Menambahkan jarak antara tombol filter */
}
.filter-label {
  color: #36373f;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

.reset-filter {
  margin-left: 30px;
}

.adjust-left {
  margin-right: -8px; /* Sesuaikan nilai ini sesuai kebutuhan */
}
</style>
